import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Dimmer, Loader, Segment } from 'semantic-ui-react/dist/commonjs'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import 'semantic-ui-css/components/sticky.min.css'
import { Sticky } from 'semantic-ui-react'
import Ad from './Ad'
import FORMATS from './AdFormats'
import MostRead from '../components/MostReadArticles'
import EditorsChoice from '../components/EditorsChoice'
import { subscribeNewsletter, updateForm, reinitialize } from '../../store/subscribe'
import { fetchMagazineCover } from '../../store/magazineCover'
import { fetchWeather } from '../../store/weather'
import { setMostReadTab } from '../../store/app'
import { Twitter, Facebook, Instagram, RightArrow, RSS as Rss } from 'ion-icon'
import cloudy from '../static/weather/cloudy.svg'
import pcloudy from '../static/weather/pcloudy.svg'
import rain from '../static/weather/rain.svg'
import sunny from '../static/weather/sunny.svg'
import thunder from '../static/weather/thunder.svg'
import windy from '../static/weather/windy.svg'
import SACovidButton from '../static/general/SAcoronavirus-button.jpg'

const pictures = {
  sunny,
  windy,
  cloudy,
  partlycloudyday: pcloudy,
  showersday: rain,
  modheavyrain: rain,
  rainy: rain,
  thunder
}

const mpuRHS = [
  [[1024, 0], [[300, 250], [3, 3]]],
  [[640, 0], [[300, 250], [336, 280], [3, 3]]],
  [[0, 0], [[300, 600], [336, 280], [320, 100], [320, 50], [300, 250], [300, 100], [300, 50], [3, 3]]]
]

export class Sidebar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      sticky: null
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onTabMostReadSelected = this.onTabMostReadSelected.bind(this)
    this.onTabEditorsChoiceSelected = this.onTabEditorsChoiceSelected.bind(this)
  }

  setStickyRef = (element) => {
    this.setState({ sticky: element })
  }

  handleChange (event) {
    const newState = {}
    newState[event.target.name] = event.target.value
    this.props.updateForm(newState)
  }

  validateEmail (email) {
    const re = /^[^.@][^@]+@{1}[^@]+\.[^@]+[^.@]$/i
    return email.match(re)
  }

  handleSubmit (event) {
    event.preventDefault()
    if (!this.validateEmail(this.props.email)) {
      this.props.updateForm({ error: 'Please enter a valid email address' })
      return false
    }
    this.props.subscribeNewsletter(this.props.email)
    return true
  }

  onTabMostReadSelected (e) {
    this.props.setMostReadTab(0)
  }

  onTabEditorsChoiceSelected (e) {
    this.props.setMostReadTab(1)
  }

  componentDidMount () {
    this.props.reinitialize()
    this.props.fetchWeather()
    if (!this.props.magazineCover) {
      this.props.fetchMagazineCover()
    }
  }

  renderDate () {
    const monthNumbers = {
      '01': 'January',
      '02': 'February',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'August',
      '09': 'September',
      10: 'October',
      11: 'November',
      12: 'December'
    }
    let today = new Date()
    let dd = today.getDate()
    let mm = today.getMonth() + 1
    const yyyy = today.getFullYear()
    if (dd < 10) {
      dd = '0' + dd
    }
    if (mm < 10) {
      mm = '0' + mm
    }
    today = dd + ' ' + monthNumbers[mm] + ' ' + yyyy
    return today
  }

  render () {
    const props = this.props
    const showForm = !props.hasSubmited && !props.isSubmiting
    const submitting = props.isSubmiting
    const showThankYou = props.hasSubmited
    return (
      <aside className='sidebar' ref={this.setStickyRef}>
        <Sticky context={this.sticky}>
          <div className='read-choice'>
            <input id='most-read' type='radio' name='tabs' checked={this.props.mostReadTab === 0} onChange={() => this.onTabMostReadSelected()} />
            <label htmlFor='most-read'>Most Read</label>
            <input id='editors-choice' type='radio' name='tabs' checked={this.props.mostReadTab === 1} onChange={() => this.onTabEditorsChoiceSelected()} />
            <label htmlFor='editors-choice'>Editor's Choice</label>
            <div className='content' id='sidebar-articles'>
              {this.props.mostReadTab === 0
                ? (<MostRead {...props} section='' title='Daily Voice' />)
                : (<EditorsChoice {...props} section='' title='Daily Voice' />)}
            </div>
          </div>
          <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.adPath || props.section}`} slotId='mpu-topright1' targeting={{ mpu: 'topright1' }} collapseEmptyDiv className='advert-sidebar' mapping={mpuRHS} {...FORMATS.mpu} />
          <div className='follow-us'>
            <h3>
              <span>Follow us</span>
            </h3>
            <a href={process.env.RAZZLE_SITE_FACEBOOK} className='facebook' target='_blank' rel='noopener noreferrer'>
              <Facebook width='24' height='24' />
              <span>Like Daily Voice on Facebook</span>
            </a>
            <a href={process.env.RAZZLE_SITE_TWITTER} className='twitter' target='_blank' rel='noopener noreferrer'>
              <Twitter width='24' height='24' fill='white' />
              <span>Follow Daily Voice on Twitter</span>
            </a>
            <a href={process.env.RAZZLE_SITE_INSTAGRAM} className='instagram' target='_blank' rel='noopener noreferrer'>
              <Instagram width='24' height='24' />
              <span>Follow Daily Voice on Instagram</span>
            </a>
            <a href={process.env.RAZZLE_SITE_RSS} target='_blank' className='instagram' rel='noopener noreferrer'>
              <Rss width='24' height='24' />
              <span>Subscribe to Daily Voice RSS Feed</span>
            </a>
          </div>
          <div className='banner-button'>
            <a href='https://sacoronavirus.co.za/' target='_blank' rel='noopener noreferrer nofollow'>
              <img src={SACovidButton} title='COVID-19 Resource Portal' alt='COVID-19 Resource Portal' />
            </a>
          </div>
          <div className='weather'>
            <h3><span>Weather</span></h3>
            <h4>Today<br />{this.renderDate()}</h4>
            {this.props.hasFetched && this.props.weather &&
              <section className='forecast'>
                <img src={pictures[props.weather.Forecasts.ForecastDay1.Day.Weather.Picture]} alt={props.weather.Forecasts.ForecastDay1.Day.Weather.WeatherFull} /> <span>{props.weather.Forecasts.ForecastDay1.Temperature.AppTemp}&deg;</span>
              </section>}
            {this.props.hasError &&
              <section className='forecast'>
                <p>Error: {this.props.error}</p>
              </section>}
            <Link to='/weather'>View 7 day forecast <RightArrow width='16' height='16' /></Link>
          </div>
          <div className='newsletter-subscribe'>
            <h3>
              <span>On Sale Now</span>
            </h3>
            {this.props.magazineCover &&
              <img src={this.props.magazineCover.image} alt='magazine cover' />}
          </div>
          {this.props.children}
          <div className='newsletter-subscribe'>
            <h3>
              <span>Subscribe</span>
            </h3>
            {showForm &&
              <>
                <form name='item-form' id='item-form' onSubmit={this.handleSubmit}>
                  <label htmlFor='email'>Sign up to receive breaking news in your inbox.</label>
                  {props.error &&
                    <small style={{ color: 'red' }}>{this.props.error ? this.props.error : 'An error occured, please try again'}</small>}
                  <input type='email' name='email' id='email' value={props.email} placeholder='Email Address' onChange={this.handleChange} required />
                  <button type='submit'>Subscribe</button>
                </form>
              </>}
            {submitting &&
              <>
                <Helmet title='Article' />
                <Segment>
                  <Dimmer active>
                    <Loader indeterminate size='large'>Submitting subscription.....</Loader>
                  </Dimmer>
                </Segment>
              </>}
            {showThankYou &&
              <>
                <Helmet title='Article' />
                <h3>Thank you for Subscribing!</h3>
                <p>We have sent a confirmation email to the email address that you provided. Click on the link in the email to confirm your subscription to our newsletter.</p>
              </>}
          </div>
          <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.adPath || props.section}`} slotId='mpu-bot' targeting={{ mpu: 'bot' }} collapseEmptyDiv className='advert-sidebar' {...FORMATS.mpu600} />
        </Sticky>
      </aside>
    )
  }
}

const mapStateToProps = (state) => (Object.assign({}, state.subscribe, state.weather, state.app, state.magazineCover))

const mapDispatchToProps = (dispatch) => bindActionCreators({
  subscribeNewsletter,
  updateForm,
  reinitialize,
  fetchWeather,
  setMostReadTab,
  fetchMagazineCover
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
