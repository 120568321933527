import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { NavLink } from 'ion-navchevron'
import logo from '../../static/general/logo.svg'
import { Button } from 'semantic-ui-react'
import { MenuIco, Search, Close } from 'ion-icon'
import { defaultWidth, DesktopTablet, Mobile } from 'ion-media'
// import Ad from '../Ad'
// import FORMATS from '../AdFormats'

export default class Header extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isSearchOpen: false
    }
  }

  render () {
    return (
      <header role='banner'>
        <a href='#main-content' className='show-on-focus'>
          Skip to content
        </a>
        <div className='wrapper'>
          <nav className='commercial-nav'>
            <ul>
              <li><a rel='noopener noreferrer' href='https://www.iol.co.za' target='_blank' title='IOL' id='iol-commerical-nav'>IOL</a></li>
              <li><a rel='noopener noreferrer' href='https://iolproperty.co.za/?referrer=dv' target='_blank' title='Property360' id='iol-property-commerical-nav'>IOLProperty</a></li>
              <li><a href='https://www.stylemode.co.za' title='StyleMode' target='_blank' rel='noopener noreferrer nofollow'>StyleMode</a></li>
              <li><a rel='noopener noreferrer' href='https://www.tyi.co.za' target='_blank' title='TYI' id='young-independent-commerical-nav'>TYI</a></li>
              <li><a rel='noopener noreferrer' href='https://www.voices360.co.za' target='_blank' title='Voices360' id='voices360-commerical-nav'>Voices360</a></li>
              <li><a rel='noopener noreferrer' href='https://blog.talent360.co.za/' target='_blank' title='Talent360' id='talent360-commerical-nav'>Talent360</a></li>
            </ul>
            <div>{new Date().toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' })}</div>
          </nav>
        </div>
        <div className='wrapper'>
          <Mobile values={{ deviceWidth: defaultWidth }}>
            <Button className='menu-btn' onClick={() => this.props.toggleMenu()}><MenuIco width='24' height='24' /></Button>
          </Mobile>
          <Link to='/' className='logo'>
            <img src={logo} alt='Daily Voice' />
          </Link>
          {/* <div className='advert'>
            <img src='https://via.placeholder.com/468x60.jpg?text=Leaderboard Header Idea' alt='' />
          </div> */}
        </div>
        <DesktopTablet values={{ deviceWidth: defaultWidth }}>
          <div className='wrapper'>
            <nav>
              <ul>
                <li>
                  <NavLink to='/' exact>Home</NavLink>
                </li>
                <li>
                  <NavLink to='/news'>News</NavLink>
                  <ul>
                    <li>
                      <Link to='/news/western-cape'>Western Cape</Link>
                    </li>
                    <li>
                      <Link to='/news/national'>National</Link>
                    </li>
                    <li>
                      <Link to='/news/international'>International</Link>
                    </li>
                    <li>
                      <Link to='/news/business'>Business</Link>
                    </li>
                    <li>
                      <Link to='/news/politics'>Politics</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink to='/sport'>Sport</NavLink>
                  <ul>
                    <li>
                      <Link to='/sport/highlights'>Highlights</Link>
                    </li>
                    <li>
                      <Link to='/sport/cricket'>Cricket</Link>
                    </li>
                    <li>
                      <Link to='/sport/rugby'>Rugby</Link>
                    </li>
                    <li>
                      <Link to='/sport/soccer'>Soccer</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink to='/lifestyle-entertainment'>Lifestyle &amp; Entertainment</NavLink>
                  <ul>
                    <li>
                      <Link to='/lifestyle-entertainment/celebrity'>Celebrity</Link>
                    </li>
                    <li>
                      <Link to='/lifestyle-entertainment/entertainment'>Entertainment</Link>
                    </li>
                    <li>
                      <Link to='/lifestyle-entertainment/cape-spy'>Cape Spy</Link>
                    </li>
                    <li>
                      <Link to='/lifestyle-entertainment/cinema'>Cinema</Link>
                    </li>
                    <li>
                      <Link to='/lifestyle-entertainment/gadget-games'>Gadget &amp; Games</Link>
                    </li>
                    <li>
                      <Link to='/lifestyle-entertainment/tonight'>Tonight</Link>
                    </li>
                    <li>
                      <Link to='/lifestyle-entertainment/recipes'>Recipes</Link>
                    </li>
                    <li>
                      <Link to='/lifestyle-entertainment/lifestyle'>Lifestyle</Link>
                    </li>
                    <li>
                      <Link to='/lifestyle-entertainment/travel'>Travel</Link>
                    </li>
                    <li>
                      <Link to='/lifestyle-entertainment/parenting'>Parenting</Link>
                    </li>
                    <li>
                      <Link to='/lifestyle-entertainment/health-beauty'>Health &amp; Beauty</Link>
                    </li>
                    <li>
                      <Link to='/lifestyle-entertainment/horoscope'>Horoscope</Link>
                    </li>
                    <li>
                      <Link to='/lifestyle-entertainment/photo-of-the-day'>Photo of the Day</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink to='/multimedia'>Multimedia</NavLink>
                </li>
                <li>
                  <NavLink to='/opinion'>Opinion</NavLink>
                  <ul>
                    <li>
                      <NavLink to='/opinion/sport'>Sport</NavLink>
                      <ul className='sub-mnu-nav'>
                        <li>
                          <Link to='/opinion/sport/dudley-carstens'>Dudley Carstens</Link>
                        </li>
                        <li>
                          <Link to='/opinion/sport/matthew-marcus'>Matthew Marcus</Link>
                        </li>
                        <li>
                          <Link to='/opinion/sport/nick-feinberg'>Nick Feinberg</Link>
                        </li>
                        <li>
                          <Link to='/opinion/sport/julia-stuart'>Julia Stuart</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink to='/opinion/motoring'>Motoring</NavLink>
                      <ul className='sub-mnu-nav'>
                        <li>
                          <Link to='/opinion/motoring/bobby-nitro'>Bobby Nitro</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink to='/opinion/current-affairs'>Current Affairs</NavLink>
                      <ul className='sub-mnu-nav'>
                        <li>
                          <Link to='/opinion/current-affairs/bobby-brown'>Bobby Brown</Link>
                        </li>
                        <li>
                          <Link to='/opinion/current-affairs/munier-grootbek'>Munier Grootbek</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink to='/opinion/lifestyle'>Lifestyle</NavLink>
                      <ul className='sub-mnu-nav'>
                        <li>
                          <Link to='/opinion/lifestyle/cooksisters'>Cooksisters</Link>
                        </li>
                        <li>
                          <Link to='/opinion/lifestyle/waseef-piekaan'>Waseef Piekaan</Link>
                        </li>
                        <li>
                          <Link to='/opinion/lifestyle/auntie-pearl'>Auntie Pearl</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink to='/competitions'>Competitions</NavLink>
                </li>
                <li>
                  <NavLink to='/contact-us'>Contact</NavLink>
                </li>
                <li className='search-btn'>
                  <Link to='#' onClick={() => this.setState({ isSearchOpen: !this.state.isSearchOpen })}>
                    {(!this.state.isSearchOpen &&
                      <Search width='24' height='24' />
                    )}
                    {(this.state.isSearchOpen &&
                      <Close width='24' height='24' />
                    )}
                  </Link>
                </li>
              </ul>
              {(this.state.isSearchOpen &&
                <form action='/search' method='get' className='search-form'>
                  <input type='search' placeholder='Search Daily Voice' name='q' id='q' />
                  <button><Search width='24' height='24' /></button>
                </form>
              )}
            </nav>
          </div>
        </DesktopTablet>
      </header>
    )
  }
}
